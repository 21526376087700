import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { useGetUser } from '@fingo/lib/hooks';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { InvoicesManagerTabs } from '../routes';

const InvoicesManager = () => (
  <FingoPaper tabs={InvoicesManagerTabs(useGetUser(), useGetCountryFromUrl())} />
);

export default InvoicesManager;
